import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  .icon-wrapper {
    width: 340px;
    overflow: hidden;
    @media ${device.desktopLG} {
      width: auto;
    }
  }

  .icons {
    margin-right: 7px;
    @media ${device.tablet} {
      margin-right: 16px;
    }
  }
  
  .current-icon {
    border-radius: 45px;
    background-color: ${orange.extra};
    padding: 10px;
  }

  .another-icon {
    padding: 10px;
  }

  .margin-icon {
    right: 65px;
    position: relative;
    @media ${device.tablet} {
      right: 128px;
    }
  }
  
  .margin-icon-none {
    right: 0 !important;
  }

  .opacity {
    position: absolute;
    float: right;
    z-index: 999;
    right: 0;
  }
`

import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import dataCMS from 'src/assets/data/consorcio/consorcio.json'

import usePageQuery from '../../pageQuery'

import { Section, Button, Link } from './style'

type consorcioBanner = {
  title: string;
  description: string;
  image: {
    url: string;
    alt: string;
  };
}

type heroProps = {
  setIsOpen: Function;
  isMobile: boolean;
  oneLink: string;
}

const Hero = ({ setIsOpen, isMobile, oneLink }: heroProps) => {
  const data = usePageQuery()
  const cmsData: consorcioBanner[] = dataCMS
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='pt-3 pt-md-5 pb-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6 col-xl-5 offset-xl-1 order-md-last mb-4 mb-md-0'>
            {cmsData[2].image.url ? <img src={cmsData[2].image.url} alt={cmsData[2].image.alt} className='img-fluid' /> : <Img fluid={data.hubConsorcioImobiliarioHero.fluid} alt='Pai abrançando filhos sentado no chão enconstado no sofá' />}
          </div>
          <div className='col-12 col-md-6 pr-md-0'>
            <h1
              className='font-sora fs-24 lh-30 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-50 text-grayscale--500 fw-600 mb-3'
              dangerouslySetInnerHTML={{ __html: cmsData[2].title }}
            />
            <p
              className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 fw-400 mb-0'
              dangerouslySetInnerHTML={{ __html: cmsData[2].description }}
            />
            {
              isMobile ? (
                <Link
                  href={oneLink}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: 'Contratar agora no Super App',
                      section_name: cmsData[2].title,
                    })
                  }}
                >
                  Contratar agora no Super App
                </Link>
              ) : (
                <Button onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    element_name: 'Contratar agora no Super App',
                    section_name: cmsData[2].title,
                  })
                }}
                >
                  Contratar agora no Super App
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero

import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

export const Card = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid ${grayscale[100]};
  box-shadow: 0px 0px 40px rgba(60, 112, 190, 0.08);
  border-radius: 8px;
  text-align: center;
  padding: 20px;

  .title {
    font-family: Sora;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: block;
    margin-left: 11px;
  }

  .text {
    font-family: Inter;
    font-size: 14px;
    line-height: 17px;
    display: block;
    margin-bottom: 4px;
  }

  .price {
    font-family: Sora;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: block;
  }

  &.white {
    background: ${white};

    .title {
      color: ${grayscale[500]};
    }

    .text, .price { 
      color: ${orange.extra};
    }

  }

  &.black {
    background: ${grayscale[500]};

    .title, .text, .price {
      color: ${white};
    }
  }
`

export const Button = styled.button`
  width: 100%;
  height: 40px;
  background: ${orange.extra};
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  border: none;
  margin-top: 32px;

  &:focus {
    outline: none;
  }
`
export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: ${orange.extra};
  border-radius: 8px;
  color: ${white};
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: ${white};
    opacity: 0.9;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 33px;
  }
  @media (min-width: ${breakpoints.xl}) {
    margin-top: 27px;
  }
`

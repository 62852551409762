import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

export const Section = styled.section`
  .cards {
    margin-top: 24px;
    position: relative;

    @media (min-width: ${breakpoints.md}) {
      margin-top: 86px;
    }
  }

  .line {
    width: 1px;
    height: 362px;
    background-color: ${orange.extra};
    left: 21px;
    top: 80px;
    position: absolute;

    @media (min-width: ${breakpoints.md}) {
      position: static;
      width: 70%;
      height: 1px;
      margin-bottom: 4px;
    }
  }

  .description {
    font-size: 16px;
    line-height: 19px;

    @media (min-width: ${breakpoints.md}) {
      font-size: 14px;
      line-height: 17px;
    }

    @media (min-width: ${breakpoints.lg}) {
      font-size: 18px;
      line-height: 22px;
    }
  }
`
export const Card = styled.div`
  width: 100%;
  height: 157px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 16px 24px 32px;
  border-radius: 12px 12px 12px 0px;
  position: relative;
  background: ${grayscale[100]};
  
  @media (min-width: ${breakpoints.md}) {
    height: 129px;
    padding: 24px 18px;
    text-align: center;
  }

  .content {
    width: 54px;
    height: 48px;
    background: ${orange.extra};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -36px;

    @media (min-width: ${breakpoints.md}) {
      left: auto;
      right: auto;
      top: -28px;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 25px;

    @media (min-width: ${breakpoints.md}) {
      font-size: 16px;
      line-height: 20px;
    }
  }

`
export const Button = styled.button`
  width: 100%;
  height: 40px;
  background: ${orange.extra};
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-top: 24px;
  color: ${white};
  border: none;

  &:focus {
    outline: none;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 68px;
    max-width: 650px;
  }
`
export const Circle = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: ${orange.extra};
  border-radius: 8px;
  color: ${white};
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: ${white};
    opacity: 0.9;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 33px;
  }
  @media (min-width: ${breakpoints.xl}) {
    margin-top: 27px;
  }
`

import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      hubConsorcioImobiliarioHero: imageSharp(fluid: { originalName: { regex: "/hub-consorcio-imobiliario-hero/" }}) {
        fluid(maxWidth: 644, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      hubConsorcioImobiliarioYourPlans1: imageSharp(fluid: { originalName: { regex: "/hub-consorcio-imobiliario-your-plans-1/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      hubConsorcioImobiliarioYourPlans2: imageSharp(fluid: { originalName: { regex: "/hub-consorcio-imobiliario-your-plans-2/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      hubConsorcioImobiliarioYourPlans3: imageSharp(fluid: { originalName: { regex: "/hub-consorcio-imobiliario-your-plans-3/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      hubConsorcioImobiliarioYourPlans4: imageSharp(fluid: { originalName: { regex: "/hub-consorcio-imobiliario-your-plans-4/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      ourClientsAutoMotoStarts: imageSharp(fluid: { originalName: { regex: "/our-clients-auto-moto-starts/" }}) {
        fluid(maxWidth: 120, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      imobiliarioYourDreams: imageSharp(fluid: { originalName: { regex: "/imobiliario-your-dreams/" }}) {
        fluid(maxWidth: 478, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery

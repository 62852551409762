import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

export const Card = styled.div`
  img {
    width: 100%;
    height: 244px;
    object-fit: cover;
  }

  .content {
    padding: 16px 24px;
    border-radius: 16px 16px 0px 0px;
    margin-top: -16px;
    background: ${white};
    position: relative;

    @media (min-width: ${breakpoints.md}) {
      padding: 16px 17px;
    }

    @media (min-width: ${breakpoints.lg}) {
      padding: 16px 24px;
    }

    &-inter {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      background: ${orange.extra};
      border-radius: 4px;
      padding: 0.5px 4px;
      color: ${white};
      margin-right: 16px;
    }

    &-shop {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: ${grayscale[500]};
      border-radius: 4px;
      padding: 0.5px 4px;
      background: ${grayscale[100]};
    }

    p {
      height: 59px;
      overflow: hidden;
    }
  }
`

import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { gray, orange, white } from 'src/styles/colors'

export const Section = styled.section`
  background: ${gray[100]};
  min-height: calc(100vh - 64px);

  @media (min-width: ${breakpoints.md}) {
    min-height: calc(455px + 49px);
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: calc(624px + 75px);
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 100vh;
  }
`
export const Button = styled.button`
  width: 100%;
  height: 48px;
  background: ${orange.extra};
  border-radius: 8px;
  color: ${white};
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  border: none;
  margin-top: 32px;

  &:focus {
    outline: none;
  }
`
export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: ${orange.extra};
  border-radius: 8px;
  color: ${white};
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: ${white};
    opacity: 0.9;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 33px;
  }
  @media (min-width: ${breakpoints.xl}) {
    margin-top: 27px;
  }
`

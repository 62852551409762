import React, { useState, useLayoutEffect } from 'react'
import Img from 'gatsby-image'

import dataJSON from '../../assets/data/your-plans.json'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useWidth from 'src/hooks/window/useWidth'

import usePageQuery from '../../pageQuery'

import { Section } from './style'

const Images = [
  'hubConsorcioImobiliarioYourPlans1',
  'hubConsorcioImobiliarioYourPlans2',
  'hubConsorcioImobiliarioYourPlans3',
  'hubConsorcioImobiliarioYourPlans4',
]

type dataProps = {
  icon: string;
  title: string;
  description: string;
  image: string;
  alt: string;
}

const WIDTH_MD = 768

const YourPlans = () => {
  const [ imageIndex, setImageIndex ] = useState(0)
  const [ iconIndex, setIconIndex ] = useState(0)
  const [ isMobile, setIsMobile ] = useState(true)

  const data = usePageQuery()
  const width = useWidth(300)

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  function changeImage (nextSlide: number) {
    setImageIndex(nextSlide)
    setIconIndex(nextSlide)
  }

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5'>
            {
              isMobile && (
                <div className='col-12 mb-4'>
                  <Img fluid={data[Images[imageIndex]].fluid} alt={dataJSON[imageIndex].alt} />
                </div>
              )
            }
            <span className='fs-14 lh-17  text-grayscale--300 fw-600 mb-4'>Consórcio Imobiliário</span>
            <h2 className='font-sora fs-24 lh-30 fs-lg-40 lh-lg-50 text-grayscale--500 fw-600 mb-4'>Realize seus planos</h2>
            <div className='d-flex justify-content-start icon-wrapper'>
              {dataJSON.map((item: dataProps, index: number) =>
                (
                  <div
                    key={index}
                    className={`${iconIndex === index ? 'current-icon' : 'another-icon'} ${iconIndex > 4 && isMobile ? 'margin-icon' : 'margin-icon-none'} icons d-flex align-items-center`}
                  >
                    <OrangeDsIcon
                      size='MD'
                      color={iconIndex === index ? 'white' : 'black'}
                      icon={item.icon}
                    />
                  </div>
                ))
              }
            </div>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              beforeChange={(nextSlide: number) => changeImage(nextSlide)}
            >
              {
                dataJSON.map((item: dataProps) => (
                  <div key={item.title}>
                    <h3 className='font-sora fs-18 lh-22 text-grayscale--500 fw-600 mt-4 mb-4 d-block'>{item.title}</h3>
                    <p className='fs-14 lh-17 text-grayscale--400'>{item.description}</p>
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
          {
            !isMobile && (
              <div className='col-12 col-md-6 col-xl-5'>
                <Img fluid={data[Images[imageIndex]].fluid} alt={dataJSON[imageIndex].alt} />
              </div>
            )
          }
        </div>
      </div>
    </Section>
  )
}

export default YourPlans

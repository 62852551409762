import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Section, Card, Button, Circle, Link } from './style'

type benefitsProps = {
  setIsOpen: Function;
  isMobile: boolean;
}

const Benefits = ({ setIsOpen, isMobile }: benefitsProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='font-sora fs-24 lh-30 text-grayscale--500 fw-600 mb-4 text-md-center'>
              Vantagens do Consórcio Imobiliário Inter
            </h2>
            <p className='text-grayscale--500 fw-400 mb-0 text-md-center description'>
              Nosso consórcio de imóveis é a melhor maneira de você conquistar sua casa própria.
            </p>

            <div className='row d-flex align-items-center justify-content-end justify-content-center cards'>
              <div className='line' />
              <div className='col-10 col-md-4 mb-4 mb-md-0'>
                <Card>
                  <div className='content'>
                    <Circle>
                      <OrangeDsIcon icon='check' size='SM' color='#ff7a00' />
                    </Circle>
                  </div>
                  <h3 className='font-sora text-grayscale--500 fw-600 mb-0'>Sem taxa de adesão</h3>
                </Card>
              </div>
              <div className='col-10 col-md-4 mb-4 mb-md-0'>
                <Card>
                  <div className='content'>
                    <Circle>
                      <OrangeDsIcon icon='check' size='SM' color='#ff7a00' />
                    </Circle>
                  </div>
                  <h3 className='font-sora text-grayscale--500 fw-600 mb-0'>
                    <span className='d-md-block'>Taxa de administração</span> de 22%
                  </h3>
                </Card>
              </div>
              <div className='col-10 col-md-4'>
                <Card>
                  <div className='content'>
                    <Circle>
                      <OrangeDsIcon icon='check' size='SM' color='#ff7a00' />
                    </Circle>
                  </div>
                  <h3 className='font-sora text-grayscale--500 fw-600 mb-0'>
                    <span className='d-md-block'>Parcelas mensais a </span>
                    partir de R$ 465,76
                  </h3>
                </Card>
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              {
                isMobile ? (
                  <Link
                    href='https://intergo.app/fc7e1053'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_02',
                        element_action: 'click button',
                        element_name: 'Contrate agora pelo App',
                        section_name: 'Vantagens do Consórcio Imobiliário Inter',
                        redirect_url: 'https://intergo.app/fc7e1053',
                      })
                    }}
                  >
                    Contrate agora pelo App
                  </Link>
                ) : (
                  <Button onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      element_action: 'click button',
                      element_name: 'Contrate agora pelo App',
                      section_name: 'Vantagens do Consórcio Imobiliário Inter',
                    })
                  }}
                  >
                    Contrate agora pelo App
                  </Button>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Benefits
